@font-face {
  font-family: icons;
  src: url("icons.fb9635e6.eot#iefix") format("embedded-opentype"), url("icons.4b7acab4.woff2") format("woff2"), url("icons.0f149f8e.woff") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  line-height: 1;
  font-family: icons !important;
  font-weight: normal !important;
}

.icon-00-baseline:before {
  content: "";
}

.icon-artfight:before {
  content: "";
}

.icon-instagram:before {
  content: "";
}

.icon-paypal:before {
  content: "";
}

.icon-tos:before {
  content: "";
}

.icon-toyhouse:before {
  content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
}

a:active, a:hover {
  outline: 0;
  text-decoration: underline;
}

b, strong {
  font-weight: bold;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

@font-face {
  font-family: Comic Neue;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  src: url("comic-neue-latin-400-normal.b4996230.woff2") format("woff2"), url("comic-neue-latin-400-normal.c56dc814.woff") format("woff");
}

body {
  color: #000;
  font-family: Comic Neue, Fira Sans, Helvetica Neue, Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.375;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
  overflow-wrap: break-word;
}

.text-right {
  text-align: right;
}

h1, h2, h3, h4, h5, blockquote, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 700;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5, blockquote {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

blockquote {
  margin-left: 0;
  margin-right: 0;
}

a {
  color: #334f30;
}

.icon-2x {
  font-size: 2em;
}

.text-muted {
  color: #222;
}

.text-small {
  font-size: .8em;
}

.text-uppercase {
  text-transform: uppercase;
}

.container, .wrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

@media (width >= 576px) {
  .container, .wrapper {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container, .wrapper {
    max-width: 720px;
  }
}

.row {
  flex-wrap: wrap;
  display: flex;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-1:not(:last-child) {
  padding-right: 16px;
}

.col-2 {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
}

.col-2:not(:last-child) {
  padding-right: 16px;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-3:not(:last-child) {
  padding-right: 16px;
}

.col-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.col-4:not(:last-child) {
  padding-right: 16px;
}

.col-5 {
  flex: 0 0 41.6667%;
  max-width: 41.6667%;
}

.col-5:not(:last-child) {
  padding-right: 16px;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-6:not(:last-child) {
  padding-right: 16px;
}

.col-7 {
  flex: 0 0 58.3333%;
  max-width: 58.3333%;
}

.col-7:not(:last-child) {
  padding-right: 16px;
}

.col-8 {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
}

.col-8:not(:last-child) {
  padding-right: 16px;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-9:not(:last-child) {
  padding-right: 16px;
}

.col-10 {
  flex: 0 0 83.3333%;
  max-width: 83.3333%;
}

.col-10:not(:last-child) {
  padding-right: 16px;
}

.col-11 {
  flex: 0 0 91.6667%;
  max-width: 91.6667%;
}

.col-11:not(:last-child) {
  padding-right: 16px;
}

.col-12, .col, .col-big-12, .col-big-11, .col-big-10, .col-big-9, .col-big-8, .col-big-7, .col-big-6, .col-big-5, .col-big-4, .col-big-3, .col-big-2, .col-big-1, .col-med-12, .col-med-11, .col-med-10, .col-med-9, .col-med-8, .col-med-7, .col-med-6, .col-med-5, .col-med-4, .col-med-3, .col-med-2, .col-med-1, .col-smol-12, .col-smol-11, .col-smol-10, .col-smol-9, .col-smol-8, .col-smol-7, .col-smol-6, .col-smol-5, .col-smol-4, .col-smol-3, .col-smol-2, .col-smol-1, .col-tiny-12, .col-tiny-11, .col-tiny-10, .col-tiny-9, .col-tiny-8, .col-tiny-7, .col-tiny-6, .col-tiny-5, .col-tiny-4, .col-tiny-3, .col-tiny-2, .col-tiny-1 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (width >= 576px) {
  .col-tiny-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-tiny-1:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-tiny-2:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-tiny-3:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-tiny-4:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-tiny-5:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-tiny-6:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-tiny-7:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-tiny-8:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-tiny-9:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-tiny-10:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-tiny-11:not(:last-child) {
    padding-right: 16px;
  }

  .col-tiny-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (width >= 768px) {
  .col-smol-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-smol-1:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-smol-2:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-smol-3:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-smol-4:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-smol-5:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-smol-6:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-smol-7:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-smol-8:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-smol-9:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-smol-10:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-smol-11:not(:last-child) {
    padding-right: 16px;
  }

  .col-smol-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (width >= 992px) {
  .col-med-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-med-1:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-med-2:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-med-3:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-med-4:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-med-5:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-med-6:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-med-7:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-med-8:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-med-9:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-med-10:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-med-11:not(:last-child) {
    padding-right: 16px;
  }

  .col-med-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (width >= 1300px) {
  .col-big-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-big-1:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-big-2:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-big-3:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-big-4:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-big-5:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-big-6:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-big-7:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-big-8:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-big-9:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-big-10:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-big-11:not(:last-child) {
    padding-right: 16px;
  }

  .col-big-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

body {
  background-image: linear-gradient(#e5c890, #d6a952);
}

.wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  min-height: 100vh;
  display: flex;
  position: relative;
}

.wrapper > * {
  margin-top: 16px;
}

.wrapper > :last-child {
  margin-bottom: 9rem;
}

.sheet-filled {
  background-color: #fafafae6;
  background-image: url("comb.24fc5254.svg");
  background-size: 20%;
  padding: 16px;
  animation: 30s linear infinite move-background;
  box-shadow: 0 10px 20px #00000030, 0 6px 6px #0000003b;
}

@media (width >= 576px) {
  .sheet-filled {
    border-radius: 32px;
    padding: 32px;
  }
}

.sheet-tall {
  animation-duration: 60s;
}

.footer:before {
  content: "";
  background-image: url("flowers.12dc3fee.svg");
  background-repeat: repeat-x;
  background-size: auto 6rem;
  width: 100%;
  height: 6rem;
  display: block;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
}

.footer {
  background-color: #fafafa80;
  padding: 4px 8px;
  font-size: 1rem;
  position: relative;
}

#particles-js {
  z-index: -10;
  width: 100%;
  height: 100%;
  position: absolute;
}

.links {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

@keyframes move-background {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 100% 100%;
  }
}

.btn {
  color: #000;
  text-transform: uppercase;
  text-align: center;
  background-image: linear-gradient(#bbc5ba, #92a290);
  border: 4px solid #d6dcd6;
  border-radius: 16px;
  outline: none;
  min-width: 64px;
  margin: 8px 4px;
  padding: 8px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.25em;
  transition: all .3s cubic-bezier(.215, .61, .355, 1);
  display: inline-block;
  position: relative;
  transform: scale(1);
}

.btn:hover {
  border-color: #92a290;
  text-decoration: none;
}

.btn:focus {
  border-color: #d6a952;
}

.btn:active {
  transform: scale(.98);
}

.btn i {
  vertical-align: sub;
  font-size: 1.25em;
}

.m-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.logo {
  border: 8px solid #eed49f;
  border-radius: 50%;
  margin-bottom: 8px;
  transition: transform .3s cubic-bezier(.215, .61, .355, 1);
}

.logo:hover {
  transform: scale(1.05);
}

table {
  border-collapse: collapse;
  margin: 0 auto;
}

th:not(:first-of-type), td:not(:first-of-type) {
  border-left: 1px solid;
}

th:not(:last-of-type), td:not(:last-of-type) {
  border-right: 1px solid;
}

th {
  border-bottom: 1px solid;
}

tr:not(:last-of-type) {
  border-top: 1px solid;
}

th, td {
  text-align: center;
  padding: .25em .5em;
}

::-webkit-scrollbar {
  background-color: #dbb467;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-sizing: content-box;
  background-color: #dbb467;
}

::-webkit-scrollbar-thumb {
  box-sizing: content-box;
  background-color: #fafafa80;
  background-clip: content-box;
  border: none;
  min-height: 30px;
  width: 5px !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #fafafabf;
}

::-webkit-scrollbar-thumb:active {
  background-color: #fafafa;
}

::-webkit-scrollbar-corner {
  background-color: #dbb467;
}

html {
  scrollbar-color: #fafafa #dbb467;
  scrollbar-width: thin;
}
/*# sourceMappingURL=index.a52ad244.css.map */
