/*
 * SPDX-FileCopyrightText: 2023 Sefa Eyeoglu <contact@scrumplex.net>
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */

@use "@fontsource/comic-neue/scss/mixins" as ComicNeue;

@import "variables";

@include ComicNeue.faces(
    // TODO: ditto
    $directory: "~node_modules/@fontsource/comic-neue/files"
);

body {
    color: $colorTextPrimary;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.375;
    @include main-font;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
    overflow-wrap: break-word;
}

.text-right {
    text-align: right;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 700;
    line-height: 1.2;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

blockquote {
    margin-left: 0;
    margin-right: 0;
    @extend h5;
}

a {
    color: $colorLink;
}

.icon-2x {
    font-size: 2em;
}

.text-muted {
    color: $colorTextSecondary;
}

.text-small {
    font-size: 0.8em;
}

.text-uppercase {
    text-transform: uppercase;
}
