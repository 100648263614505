/*
 * SPDX-FileCopyrightText: 2023 Sefa Eyeoglu <contact@scrumplex.net>
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */

@import "variables";

body {
    background-image: linear-gradient($colorBrand, $colorBrandDark);
}

.wrapper {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    position: relative;
    min-height: 100vh;

    > * {
        margin-top: 16px;
    }

    > :last-child {
        margin-bottom: 9rem;
    }
}

.sheet {
    &-filled {
        background-color: transparentize($colorBase, 0.1);
        background-image: url(../img/comb.svg);
        background-size: 20%;
        box-shadow:
            0 10px 20px rgba(0, 0, 0, 0.19),
            0 6px 6px rgba(0, 0, 0, 0.23);

        animation: move-background 30s infinite linear;

        padding: 16px;

        @media (min-width: map_get($responsiveThresholds, tiny)) {
            border-radius: 32px;
            padding: 32px;
        }
    }

    &-tall {
        animation-duration: 60s;
    }
}

.footer::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    background-repeat: repeat-x;
    background-size: auto 6rem;
    background-image: url(../img/flowers.svg);
    height: 6rem;
    left: 0;
    right: 0;
    bottom: 100%;
}

.footer {
    position: relative;
    background-color: transparentize($colorBase, 0.5);
    padding: 4px 8px;
    font-size: 1rem;
}

#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -10;
}

.links {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

@keyframes move-background {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 100% 100%;
    }
}
