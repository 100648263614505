/*
 * SPDX-FileCopyrightText: 2023 Sefa Eyeoglu <contact@scrumplex.net>
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */

@import "variables";

.m-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.logo {
    border: 8px solid $colorBrandLighter;
    border-radius: 50%;
    margin-bottom: 8px;

    transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);

    &:hover {
        transform: scale(1.05);
    }
}

table {
    border-collapse: collapse;
    margin: 0 auto;
}

th:not(:first-of-type),
td:not(:first-of-type) {
    border-left: 1px solid;
}

th:not(:last-of-type),
td:not(:last-of-type) {
    border-right: 1px solid;
}

th {
    border-bottom: 1px solid;
}

tr:not(:last-of-type) {
    border-top: 1px solid;
}

th,
td {
    padding: 0.25em 0.5em;
    text-align: center;
}
