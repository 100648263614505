/*
 * SPDX-FileCopyrightText: 2023 Sefa Eyeoglu <contact@scrumplex.net>
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */

@import "variables";

.btn {
    position: relative;
    display: inline-block;
    min-width: 64px;
    margin: 8px 4px;
    padding: 8px;
    color: $colorTextPrimary;
    background-image: linear-gradient($colorAccent, $colorAccentDark);
    outline: none;
    border: none;
    border-radius: 16px;
    text-transform: uppercase;
    line-height: 2.25em;
    font-size: 1.25rem;
    font-weight: 700;
    text-align: center;
    transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
    border: 4px solid $colorAccentLight;
    transform: scale(1);

    &:hover {
        text-decoration: none;
        border-color: $colorAccentDark;
    }

    &:focus {
        border-color: $colorBrandDark;
    }

    &:active {
        transform: scale(0.98);
    }

    i {
        font-size: 1.25em;
        vertical-align: sub;
    }
}
