/*
 * SPDX-FileCopyrightText: 2023 Sefa Eyeoglu <contact@scrumplex.net>
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
}

a {
    text-decoration: none;
}

a:active,
a:hover {
    outline: 0;
    text-decoration: underline;
}

b,
strong {
    font-weight: bold;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

img {
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}

pre {
    overflow: auto;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}
