/*
 * SPDX-FileCopyrightText: 2023 Sefa Eyeoglu <contact@scrumplex.net>
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */

@import "variables";

::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    background-color: $colorScrollbarTrack;
}

::-webkit-scrollbar-track {
    background-color: $colorScrollbarTrack;
    box-sizing: content-box;
}

::-webkit-scrollbar-thumb {
    background-color: transparentize($colorScrollbarThumb, 0.5);
    border: none;
    background-clip: content-box;
    width: 5px !important;
    box-sizing: content-box;
    min-height: 30px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: transparentize($colorScrollbarThumb, 0.25);
}

::-webkit-scrollbar-thumb:active {
    background-color: $colorScrollbarThumb;
}

::-webkit-scrollbar-corner {
    background-color: $colorScrollbarTrack;
}

html {
    scrollbar-color: $colorScrollbarThumb $colorScrollbarTrack;
    scrollbar-width: thin;
}
