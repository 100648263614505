@font-face {
    font-family: "icons";
    src:
        url("./icons.eot?7215536ed95060144a18ea8e177329f3#iefix")
            format("embedded-opentype"),
        url("./icons.woff2?7215536ed95060144a18ea8e177329f3") format("woff2"),
        url("./icons.woff?7215536ed95060144a18ea8e177329f3") format("woff");
}

i[class^="icon-"]:before,
i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-00-baseline:before {
    content: "\f101";
}
.icon-artfight:before {
    content: "\f102";
}
.icon-instagram:before {
    content: "\f103";
}
.icon-paypal:before {
    content: "\f104";
}
.icon-tos:before {
    content: "\f105";
}
.icon-toyhouse:before {
    content: "\f106";
}
